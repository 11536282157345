import React, { useState, useEffect, useRef } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { allVacancyConfigurator } from './VacancyConfigurator'

import arrowBottom from '../../../assets/images/png/careersPage/Arrow.png'
import img1 from '../../../assets/images/png/careersPage/img1.png'
import img2 from '../../../assets/images/png/careersPage/img2.png'
import img3 from '../../../assets/images/png/careersPage/img3.png'
import img4 from '../../../assets/images/png/careersPage/img4.png'
import img5 from '../../../assets/images/png/careersPage/img5.png'
import video from '../../../assets/images/png/careersPage/video.mp4'
import video2 from '../../../assets/images/png/careersPage/video2.mp4'
import image from '../../../assets/images/png/careersPage/VD.png'
import imageForPhone from '../../../assets/images/png/careersPage/VD2.png'
import arrow from '../../../assets/images/png/careersPage/arrowBottom.png'

import * as styles from './styles.module.scss'
import Culture from './Culture'

const filters = [
  {
    id: 1,
    name: 'All areas',
    category: 'all',
  },
  {
    id: 2,
    name: 'Web development',
    category: 'web development',
  },
  {
    id: 3,
    name: 'Sales and marketing',
    category: 'sales and marketing',
  },
  {
    id: 4,
    name: 'HR',
    category: 'hr',
  },
  {
    id: 5,
    name: 'Design',
    category: 'design',
  },
  {
    id: 6,
    name: 'Management',
    category: 'managment',
  },
]

const filtersMobile = [
  {
    id: 1,
    name: 'All areas',
    category: 'all',
  },
  {
    id: 2,
    name: 'HR',
    category: 'hr',
  },
  {
    id: 3,
    name: 'Management',
    category: 'managment',
  },
  {
    id: 4,
    name: 'Design',
    category: 'design',
  },
  {
    id: 5,
    name: 'Sales and marketing',
    category: 'sales and marketing',
  },
  {
    id: 6,
    name: 'Web development',
    category: 'web development',
  },
]

const Professionals = [
  {
    id: 1,
    category: 'left',
    num: '01',
    imageSrc: img1,
    text:
      'We regularly conduct training masterclasses within our team. Our employees study scientific materials and present them to colleagues.',
  },
  {
    id: 2,
    category: 'right',
    num: '02',
    imageSrc: img2,
    text:
      'Our office has a library with useful educational literature. Each employee can take the book he is interested in and improve their skills.',
  },
  {
    id: 3,
    category: 'left',
    num: '03',
    imageSrc: img3,
    text: 'We work according to a modern system with a flexible morning.',
  },
  {
    id: 4,
    category: 'right',
    num: '04',
    imageSrc: img4,
    text:
      'We provide our employees with the opportunity to attend training seminars and lectures to improve their skills.',
  },
  {
    id: 5,
    category: 'left',
    num: '05',
    imageSrc: img5,
    text:
      'We are for a healthy lifestyle, therefore, our team building takes place on the tennis court!',
  },
]

const CareersBlock = () => {
  const [filteredVacancy, setFilteredVacancy] = useState([])
  const [open, setOpen] = useState('')
  const [active, setActive] = useState('')
  const [videoStart, setVideoStart] = useState(true)

  const dataVacancy = useStaticQuery(graphql`
    {
      allContentfulVacancy(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            id
            bodydescription {
              raw
            }
            category
            salary
            context
            title
          }
        }
      }
    }
  `)

  const vacancies = allVacancyConfigurator(
    dataVacancy.allContentfulVacancy.edges
  )

  useEffect(() => {
    setFilteredVacancy(vacancies)
  }, [])

  const handleFilterClick = (el) => {
    setFilteredVacancy(
      el.category === 'all'
        ? vacancies
        : vacancies.filter((item) => item.category === el.category)
    )
  }

  const  onActive = (el) => {
    let newID = el.id.toString();
    setActive(newID)
  }

  const videoEl = useRef(null)
  const videoElForShow = useRef(null)
  const videoElForShowMobile = useRef(null)

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        setVideoStart(false)
        console.error('Error attempting to play', error)
      })
  }

  const attemptPlayForShow = () => {
    videoElForShow &&
      videoElForShow.current &&
      videoElForShow.current.play().catch((error) => {
        console.error('Error attempting to play', error)
      })
  }

  const attemptPlayForShowMobile = () => {
    videoElForShowMobile &&
    videoElForShowMobile.current &&
    videoElForShowMobile.current.play().catch((error) => {
      console.error('Error attempting to play', error)
    })
  }

  let videoPlay =
    typeof document !== 'undefined' && document.getElementById('video')

  useEffect(() => {
    attemptPlay()
    attemptPlayForShow()
    attemptPlayForShowMobile()
    checkForVideo(videoPlay)
  }, [videoPlay])

  const checkForVideo = (videoPlay) => {
    let b = setInterval(() => {
      if (videoPlay && videoPlay.readyState >= 3) {
        clearInterval(b)
      }
    }, 1000)
  }

  return (
    <div className={styles.careersBlock}>
      <div className={`${styles.careersBlockContainer} container`}>
        <div className={`${styles.careersBlockContainerVideo} video`}>
          <div className={styles.careersBlockContainerVideoContent}>
            <div className={styles.careersBlockContainerVideoContentForm}>
              <h2>
                Treating customers <p> and employees fairly - is our slogan </p>
              </h2>
              <Link to="/contacts/">Join the team</Link>
              <video
                src={video}
                loop
                muted
                id="video"
                className={styles.careersBlockContainerVideoMp0}
                playsInline
                ref={videoEl}
              />
              <video
                src={video2}
                loop
                muted
                id="video"
                className={styles.careersBlockContainerVideoMp0}
                playsInline
                ref={videoEl}
              />
              {videoStart ? (
                <>
                  <video
                    src={video}
                    loop
                    muted
                    id="video"
                    poster={image}
                    className={styles.careersBlockContainerVideoMp4}
                    playsInline
                    ref={videoElForShow}
                  />
                  <video
                    src={video2}
                    loop
                    muted
                    id="video"
                    poster={imageForPhone}
                    className={styles.careersBlockContainerVideoMp1}
                    playsInline
                    ref={videoElForShowMobile}
                  />
                </>
              ) : (
                <>
                  <img
                    alt="Career in Justice it"
                    loading="eager"
                    src={image}
                    className={styles.careersBlockContainerVideoImage1}
                  />
                  <img
                  alt="Career in Justice it"
                  loading="eager"
                  src={imageForPhone}
                  className={styles.careersBlockContainerVideoImage2}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className={`${styles.careersBlockContainerVacancies} vacancies`}>
          <h2>Current vacancies</h2>
          {typeof window !== 'undefined' && window.innerWidth > 768 ? (
            <div>
              {filters?.map(({ name, category, id }) => {
                return (
                  <div
                    onClick={() => onActive({ id })}
                    className={id.toString() === active ?
                     `${styles.activeButton} ${styles.careersBlockContainerVacanciesButton}`
                      :
                      styles.careersBlockContainerVacanciesButton}
                  >
                    <button
                      key={id}
                      onClick={() => handleFilterClick({ category })}
                    >
                      {name}
                    </button>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className={styles.careersBlockContainerVacanciesButtonMobile}>
              {filtersMobile?.map(({ name, category, id }) => {
                return (
                  <div
                    onClick={() => onActive({ id })}
                    className={id.toString() === active ?
                      `${styles.activeButton} ${styles.careersBlockContainerVacanciesButton}`
                      :
                      styles.careersBlockContainerVacanciesButton}
                  >
                    <button
                      key={id}
                      onClick={() => handleFilterClick({ category })}
                    >
                      {name}
                    </button>
                  </div>
                )
              })}
            </div>
          )}
          {filteredVacancy?.map((el, id) => (
            <div className={styles.careersBlockContainerVacanciesCarts}>
              <div className={styles.careersBlockContainerVacanciesCartsBody}>
                <div
                  className={styles.careersBlockContainerVacanciesCartsBodyCart}
                >
                  <div
                    className={
                      styles.careersBlockContainerVacanciesCartsBodyCartTitle
                    }
                  >
                    <div>
                      <div
                        className={
                          styles.careersBlockContainerVacanciesCartsBodyCartTitleTop
                        }
                      >
                        <h3>{el.title}</h3>
                      </div>
                      <div
                        className={
                          styles.careersBlockContainerVacanciesCartsBodyCartTitleText
                        }
                      >
                        {el.context.map((el) => (
                          <p>{'• ' + el}</p>
                        ))}
                      </div>
                    </div>
                    {el.id === open && open.length > 0 && (
                      <div
                        className={
                          styles.careersBlockContainerVacanciesCartsBodyDescription
                        }
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: el.bodyDescription,
                          }}
                        />
                        <Link to="/contacts/">
                          <button>Apply for vacancy</button>
                        </Link>
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      styles.careersBlockContainerVacanciesCartsBodyCartButton
                    }
                    key={id}
                  >
                    <div
                      onClick={
                        open?.length > 0
                          ? () => setOpen('')
                          : () => setOpen(el.id)
                      }
                      key={id}
                    >
                      <div className={(el.id === open && open.length > 0) && styles.arrowActive} key={id} >
                        <button key={id} className={styles.buttonStyles}>
                          <img key={id} src={arrow} alt="Button" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {filteredVacancy?.length === 0 && (
            <div className={styles.careersBlockContainerVacanciesCarts}>
              <div className={styles.careersBlockContainerVacanciesCartsBody}>
                <div
                  className={styles.careersBlockContainerVacanciesCartsBodyCart}
                >
                  <div
                    className={
                      styles.careersBlockContainerVacanciesCartsBodyCartTitle
                    }
                  >
                    <div>
                      <div
                        className={
                          styles.careersBlockContainerVacanciesCartsBodyCartTitleEmpty
                        }
                      >
                        <h3>
                          Сейчас мы не ведем набор на данные вакансии, но мы
                          всегда рады видеть в команде классных специалистов!
                        </h3>
                      </div>
                      <div
                        className={
                          styles.careersBlockContainerVacanciesCartsBodyCartTitleText
                        }
                      >
                        <p>
                          Если вы не видите необходимую вам вакансию,{' '}
                          <Link to="/contacts/">просто свяжитесь с нами</Link>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.careersBlockContainerCulture}>
          <h2>Corporate Culture</h2>
          <div className={styles.careersBlock}>
            <Culture />
          </div>
        </div>
        <div className={styles.careersBlockContainerSkilled}>
          <h2>Skilled professionals</h2>
          {Professionals?.map((el) => (
            <div className={styles.careersBlockContainerSkilledParts}>
              {el?.category === 'right' ? (
                <div className={styles.careersBlockContainerSkilledPartsRevert}>
                  <div
                    className={
                      styles.careersBlockContainerSkilledPartsRevertLeft
                    }
                  >
                    <img src={el.imageSrc} alt="Image" />
                    <div
                      className={
                        styles.careersBlockContainerSkilledPartsLeftCenter
                      }
                    >
                      <div
                        className={
                          styles.careersBlockContainerSkilledPartsLeftNum
                        }
                      >
                        {el.num}
                      </div>
                      <p>{el.text}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.careersBlockContainerSkilledPartsLeft}>
                  <img src={el.imageSrc} alt="Image" />
                  <div
                    className={
                      styles.careersBlockContainerSkilledPartsLeftCenter
                    }
                  >
                    <div
                      className={
                        styles.careersBlockContainerSkilledPartsLeftNum
                      }
                    >
                      {el.num}
                    </div>
                    <p>{el.text}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CareersBlock
