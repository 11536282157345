import React from 'react'
import Slider from 'react-slick'

import * as styles from './styles.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import icon1 from '../../../assets/images/svg/solutionBlock/solution block icon 1.svg'
import icon2 from '../../../assets/images/svg/solutionBlock/solution block icon 2.svg'
import icon3 from '../../../assets/images/svg/solutionBlock/solution block icon 3.svg'
import icon4 from '../../../assets/images/svg/solutionBlock/solution block icon 4.svg'
import icon5 from '../../../assets/images/png/careersPage/icon5.png'

const cardsArr = [
  {
    id: 1,
    imageSrc: icon1,
    title: 'A responsibility',
    text:
      'We always take responsibility for our actions, results, and future steps.',
  },
  {
    id: 2,
    imageSrc: icon2,
    title: 'Force',
    text:
      'Our strength is that we offer new ideas, take care of our client`s business.',
  },
  {
    id: 3,
    imageSrc: icon3,
    title: 'Mutual respect',
    text:
      'We value the opinion of everyone because the thoughts are the uniqueness of a person.',
  },
  {
    id: 4,
    imageSrc: icon4,
    title: 'Aspiration',
    text:
      'Striving for new things, encouraging attempts to get out of the comfort zone.',
  },
  {
    id: 5,
    imageSrc: icon5,
    title: 'Organization',
    text:
      'Structured processes, clear management, challenging tasks for each of us.',
  },
]

const CustomPaging = () => <span className={styles.solutionBlockNewDot} />

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: false,
  customPaging: CustomPaging,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const Card = ({ imageSrc, title, text }) => (
  <div className={styles.careersBlockContainerCultureCardsCard}>
    <img src={imageSrc} alt="icon" />
    <h3>{title}</h3>
    <p>{text}</p>
  </div>
)

const Culture = () => (
  <div className={styles.solutionBlockNew}>
    <div className={styles.solutionBlockNewFlex}>
      {cardsArr.map((card) => {
        const { id, ...rest } = card
        return <Card key={id} {...rest} />
      })}
    </div>
    <Slider className={styles.solutionBlockNewSlider} {...settings}>
      {cardsArr.map((card) => {
        const { id, ...rest } = card
        return <Card key={id} {...rest} />
      })}
    </Slider>
  </div>
)

export default Culture
