import { transformToReact } from '../../../helpers/format/transformToRC'

const allVacancyConfigurator = (latestPost) => {
  return latestPost.map((item) => ({
    id: item.node.id,
    title: item.node.title,
    context: item.node.context.split(','),
    bodyDescription: transformToReact(item.node['bodydescription'].raw),
    category: item.node.category,
    salary: item.node.salary,
  }))
}

export { allVacancyConfigurator }
