// extracted by mini-css-extract-plugin
export var careersBlock = "styles-module--careers-block--CSEjk";
export var careersBlockContainer = "styles-module--careers-block-container--WLOK7";
export var careersBlockContainerVideo = "styles-module--careers-block-container-video--I5jF1";
export var careersBlockContainerVideoMp0 = "styles-module--careers-block-container-video-mp0--yJxwQ";
export var careersBlockContainerVideoMp4 = "styles-module--careers-block-container-video-mp4--+H8q+";
export var careersBlockContainerVideoMp1 = "styles-module--careers-block-container-video-mp1--CZpVS";
export var careersBlockContainerVideoImage1 = "styles-module--careers-block-container-video-image1--Ot8j7";
export var careersBlockContainerVideoImage2 = "styles-module--careers-block-container-video-image2--7xv10";
export var careersBlockContainerVideoContent = "styles-module--careers-block-container-video-content--u9ihB";
export var careersBlockContainerVideoContentForm = "styles-module--careers-block-container-video-content-form--Xb3YV";
export var careersBlockContainerVacancies = "styles-module--careers-block-container-vacancies--0JPOx";
export var careersBlockContainerVacanciesButton = "styles-module--careers-block-container-vacancies-button--02-jH";
export var careersBlockContainerVacanciesButtonMobile = "styles-module--careers-block-container-vacancies-button-mobile--Kslyk";
export var careersBlockContainerVacanciesButtonActive = "styles-module--careers-block-container-vacancies-button-active--bSRi+";
export var activeBtn = "styles-module--activeBtn--LYYtu";
export var careersBlockContainerVacanciesCartsBody = "styles-module--careers-block-container-vacancies-carts-body--xB-u6";
export var careersBlockContainerVacanciesCartsBodyCart = "styles-module--careers-block-container-vacancies-carts-body-cart--tHIlK";
export var careersBlockContainerVacanciesCartsBodyCartTitle = "styles-module--careers-block-container-vacancies-carts-body-cart-title--9NUb3";
export var careersBlockContainerVacanciesCartsBodyCartTitleTop = "styles-module--careers-block-container-vacancies-carts-body-cart-title-top--VdKLA";
export var careersBlockContainerVacanciesCartsBodyCartTitleEmpty = "styles-module--careers-block-container-vacancies-carts-body-cart-title-empty--mWYiq";
export var careersBlockContainerVacanciesCartsBodyCartTitleText = "styles-module--careers-block-container-vacancies-carts-body-cart-title-text--Xwk59";
export var careersBlockContainerVacanciesCartsBodyCartButton = "styles-module--careers-block-container-vacancies-carts-body-cart-button--OB-WP";
export var careersBlockContainerVacanciesCartsBodyDescription = "styles-module--careers-block-container-vacancies-carts-body-description--UDlVG";
export var careersBlockContainerCulture = "styles-module--careers-block-container-culture--TrASz";
export var careersBlockContainerCultureCards = "styles-module--careers-block-container-culture-cards--h9Tue";
export var careersBlockContainerCultureCardsCard = "styles-module--careers-block-container-culture-cards-card---Oq2n";
export var careersBlockContainerCultureFlex = "styles-module--careers-block-container-culture-flex--t8raP";
export var careersBlockContainerCultureSlider = "styles-module--careers-block-container-culture-slider--anN1B";
export var slickDots = "styles-module--slick-dots--1ZJkl";
export var careersBlockContainerSkilled = "styles-module--careers-block-container-skilled--HVi96";
export var careersBlockContainerSkilledParts = "styles-module--careers-block-container-skilled-parts--Gseie";
export var careersBlockContainerSkilledPartsRevert = "styles-module--careers-block-container-skilled-parts-revert--Qp8Me";
export var careersBlockContainerSkilledPartsRevertLeft = "styles-module--careers-block-container-skilled-parts-revert-left--w9A1V";
export var careersBlockContainerSkilledPartsLeftNum = "styles-module--careers-block-container-skilled-parts-left-num--662a7";
export var careersBlockContainerSkilledPartsLeft = "styles-module--careers-block-container-skilled-parts-left--2h1vK";
export var careersBlockContainerSkilledPartsLeftCenter = "styles-module--careers-block-container-skilled-parts-left-center--2rE9F";
export var buttonStyles = "styles-module--buttonStyles--DMl5+";
export var arrowActive = "styles-module--arrowActive--+3E82";
export var solutionBlockNew = "styles-module--solution-block-new--QxQwo";
export var solutionBlockNewFlex = "styles-module--solution-block-new-flex--KGKL3";
export var solutionBlockNewSlider = "styles-module--solution-block-new-slider--+Lf-2";
export var solutionBlockNewDot = "styles-module--solution-block-new-dot--qlDay";
export var activeButton = "styles-module--activeButton--pmkhu";